import { BrowserRouter, Navigate, Route, Routes } from "react-router-dom";
import { Navbar } from "../common/Nabar/navbar";
import { Footer } from "../common/Footer/footer";
import { Home } from "../features/Home/home";
import { Contact } from "../features/Contact/contact";
import { About } from "../features/About/about";
import { Offer } from "../features/Offer/offer";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Projects } from "../features/Projects/projects";
import { Project } from "../features/Projects/project";

function App() {
    return (
        <BrowserRouter>
            <Navbar />
            <Routes>
                <Route path="/Strona-Główna" element={<Home />} />
                <Route path="/Kontakt" element={<Contact />} />
                <Route path="/O-Mnie" element={<About />} />
                <Route path="/Projekty-Wnętrz" element={<Projects />} />
                <Route path="/Architektura/:id" element={<Project />} />
                <Route path="/Architektura" element={<Projects />} />
                <Route
                    path={"*"}
                    element={<Navigate replace to="/Strona-Główna" />}
                ></Route>
                <Route path="/Projekty-Wnętrz/:id" element={<Project />} />
                <Route path="/Oferta" element={<Offer />} />
            </Routes>
            <ToastContainer
                position="top-center"
                autoClose={4000}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover={false}
            />
            <Footer />
        </BrowserRouter>
    );
}

export default App;
